// components/Navigation.js
import React from 'react';
import { Link } from 'react-router-dom';

function Navigation({ setLoading }) {
  const handleClick = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  };

  return (
    <nav>
      <ul>
        <li><Link to="/" onClick={handleClick}>{<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                            data-name="Layer 1" viewBox="0 0 24 24">
                            <path
                                d="M23.045,8.035l-2.045-1.607V2h-1v3.641L13.545,.567c-.91-.715-2.182-.715-3.09,0L.955,8.035c-.607,.477-.955,1.193-.955,1.965v14H8V14.5c0-.827,.673-1.5,1.5-1.5h5c.827,0,1.5,.673,1.5,1.5v9.5h8V10c0-.772-.348-1.489-.955-1.965Zm-.045,14.965h-6V14.5c0-1.378-1.121-2.5-2.5-2.5h-5c-1.379,0-2.5,1.122-2.5,2.5v8.5H1V10c0-.463,.209-.893,.572-1.179L11.072,1.353c.547-.429,1.309-.429,1.855,0l9.5,7.468c.363,.286,.572,.716,.572,1.179v13Z" />
                        </svg>}</Link></li>
        <li><Link to="/about" onClick={handleClick}>{<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                            data-name="Layer 1" viewBox="0 0 24 24">
                            <path
                                d="m21.5,4h-6.5v-1c0-1.654-1.346-3-3-3s-3,1.346-3,3v1H2.5c-1.378,0-2.5,1.121-2.5,2.5v17.5h24V6.5c0-1.379-1.122-2.5-2.5-2.5Zm-11.5-1c0-1.103.897-2,2-2s2,.897,2,2v4h-4V3Zm13,20H1V6.5c0-.827.673-1.5,1.5-1.5h6.5v3h6v-3h6.5c.827,0,1.5.673,1.5,1.5v16.5Zm-6-3.5v1.5h-1v-1.5c0-.827-.673-1.5-1.5-1.5h-5c-.827,0-1.5.673-1.5,1.5v1.5h-1v-1.5c0-1.379,1.122-2.5,2.5-2.5h5c1.378,0,2.5,1.121,2.5,2.5Zm-5-3.5c1.654,0,3-1.346,3-3s-1.346-3-3-3-3,1.346-3,3,1.346,3,3,3Zm0-5c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Z" />
                        </svg>}</Link></li>
        <li><Link to="/videos" onClick={handleClick}>{<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                            data-name="Layer 1" viewBox="0 0 24 24">
                            <path
                                d="M13.5,10H5.5c-.827,0-1.5,.673-1.5,1.5v7c0,.827,.673,1.5,1.5,1.5H13.5c.827,0,1.5-.673,1.5-1.5v-7c0-.827-.673-1.5-1.5-1.5Zm.5,8.5c0,.276-.225,.5-.5,.5H5.5c-.275,0-.5-.224-.5-.5v-7c0-.276,.225-.5,.5-.5H13.5c.275,0,.5,.224,.5,.5v7Zm9.155-9.336c-.522-.261-1.136-.206-1.601,.144l-2.555,1.917v-.724c0-2.481-2.019-4.5-4.5-4.5h-.28L9.952,1.732c-.473-.472-1.1-.732-1.768-.732H.5c-.276,0-.5,.224-.5,.5s.224,.5,.5,.5h7.685c.395,0,.781,.16,1.061,.439l3.561,3.561H4.5C2.019,6,0,8.019,0,10.5v9c0,2.481,2.019,4.5,4.5,4.5H14.5c2.481,0,4.5-2.019,4.5-4.5v-.724l2.555,1.916c.271,.204,.592,.308,.915,.307,.233,0,.467-.053,.686-.163,.521-.26,.845-.784,.845-1.367V10.53c0-.583-.324-1.106-.845-1.367Zm-5.155,10.336c0,1.93-1.57,3.5-3.5,3.5H4.5c-1.93,0-3.5-1.57-3.5-3.5V10.5c0-1.93,1.57-3.5,3.5-3.5H14.5c1.93,0,3.5,1.57,3.5,3.5v9Zm5-.03c0,.205-.109,.381-.292,.472-.185,.091-.392,.072-.554-.05l-3.154-2.366v-5.053l3.154-2.366c.162-.121,.369-.141,.554-.05,.183,.092,.292,.268,.292,.473v8.94Z" />
                        </svg>}</Link></li>
        <li><Link to="/games" onClick={handleClick}>{<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                            data-name="Layer 1" viewBox="0 0 24 24">
                            <path
                                d="m21.5,18h-2.5v-2h-1v2h-5.5V7.969c1.971-.247,3.5-1.932,3.5-3.969,0-2.206-1.794-4-4-4s-4,1.794-4,4c0,2.036,1.529,3.722,3.5,3.969v10.031H2.5c-1.379,0-2.5,1.122-2.5,2.5v3.5h24v-3.5c0-1.378-1.121-2.5-2.5-2.5ZM9,4c0-1.654,1.346-3,3-3s3,1.346,3,3-1.346,3-3,3-3-1.346-3-3Zm14,19H1v-2.5c0-.827.673-1.5,1.5-1.5h19c.827,0,1.5.673,1.5,1.5v2.5Z" />
                        </svg>}</Link></li>
        <li><Link to="/portfolio" onClick={handleClick}>{<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                            data-name="Layer 1" viewBox="0 0 24 24">
                            <path
                                d="m20.5,13h-8c-1.93,0-3.5,1.57-3.5,3.5v4c0,1.93,1.57,3.5,3.5,3.5h8c1.93,0,3.5-1.57,3.5-3.5v-4c0-1.93-1.57-3.5-3.5-3.5Zm2.5,7.5c0,1.379-1.121,2.5-2.5,2.5h-8c-1.378,0-2.5-1.121-2.5-2.5v-4c0-1.379,1.122-2.5,2.5-2.5h8c1.379,0,2.5,1.121,2.5,2.5v4Zm-10-3.5v3c0,.552.448,1,1,1s1-.448,1-1c0-.276.224-.5.5-.5s.5.224.5.5c0,1.103-.897,2-2,2s-2-.897-2-2v-3c0-1.103.897-2,2-2s2,.897,2,2c0,.276-.224.5-.5.5s-.5-.224-.5-.5c0-.552-.448-1-1-1s-1,.448-1,1Zm1-11c0-3.309-2.691-6-6-6S2,2.691,2,6s2.691,6,6,6,6-2.691,6-6Zm-6,5c-2.757,0-5-2.243-5-5S5.243,1,8,1s5,2.243,5,5-2.243,5-5,5Zm0,3.483c.018.276-.191.514-.466.532-3.663.24-6.533,3.309-6.533,6.984v1.5c0,.276-.224.5-.5.5s-.5-.224-.5-.5v-1.5c0-4.201,3.28-7.707,7.467-7.982.273-.023.513.191.532.466Zm13.318,1.363l-1.031,5.041c-.086.444-.402,1.111-1.216,1.111s-1.141-.704-1.216-1.097l-1.097-5.05c-.067-.306.167-.596.48-.596h.028c.232,0,.432.162.48.388l1.089,5.057c.024.127.084.227.159.272.049.032.094.034.144.007.063-.032.138-.133.168-.289l1.05-5.044c.047-.228.248-.391.481-.391.311,0,.544.285.482.59Z" />
                        </svg>}</Link></li>
        <li><Link to="/contact" onClick={handleClick}>{<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                            data-name="Layer 1" viewBox="0 0 24 24">
                            <path
                                d="M21.5,2H5.5c-1.38,0-2.5,1.12-2.5,2.5v15.5H24V4.5c0-1.38-1.12-2.5-2.5-2.5ZM5.5,3H21.5c.69,0,1.28,.47,1.45,1.12l-6.86,6.86c-1.32,1.32-3.63,1.32-4.95,0L4.11,3.94c.22-.55,.76-.94,1.39-.94Zm-1.5,16V5.25l6.44,6.43c.85,.85,1.98,1.32,3.18,1.32s2.33-.47,3.18-1.32l6.2-6.2v13.52H4Zm-3,3H19v1H0V7.5c0-.82,.39-1.54,1-2V22Z" />
                        </svg>}</Link></li>
      </ul>
    </nav>
  );
}

export default Navigation;