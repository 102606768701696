import React from 'react';
import styled, { keyframes } from 'styled-components';

const SceneContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background-image: url('https://images.unsplash.com/photo-1505118380757-91f5f5632de0?auto=format&fit=crop&w=1200&q=80');
  background-size: cover;
  background-position: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const CTAButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    background-color: #c0392b;
    transform: scale(1.1);
  }
`;

const CTAText = styled.p`
  color: white;
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
`;

const RealisticContactEmail = ({ email }) => {
  const handleClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <SceneContainer onClick={handleClick}>
      <Overlay>
        <CTAText>Ready to connect? Click below to send an email!</CTAText>
        <CTAButton>Contact Me</CTAButton>
      </Overlay>
    </SceneContainer>
  );
};

const Contact = () => {
  return (
    <div id="contact" className="content">
      <h1>Get in Touch</h1>
      <p>Feel free to reach out for inquiries, collaborations, or just to say hello.</p>
      <RealisticContactEmail email="christopher.bodnarchuk@gmail.com" />
    </div>
  );
};

export default Contact;