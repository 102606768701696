// components/About.js
import React from 'react';

function About() {
  return (
    <div id="about" class="content">
                <h1>"Hello Weird!"</h1>
                <p>An ungodly amount of time staring at screens, brow-encrusted, wrestling with code and trying to make
                    sense of the digital chaos that passes for progress in the '20s.
                    It's a peculiar existence, one
                    moment you're crafting elegant solutions to complex problems, and the next you're explaining to
                    Karen from accounting why her cat video won't load. Such is the life of a solutions expert in a type
                    of brave new world we only somewhat predicted. </p>

                <p class="aboutPara2">The tech landscape has been a trip, filled with late-night debugging sessions that
                    would make a gonzo
                    journalist adventure seem tame by comparison. I've danced with databases in the pale moonlight.
                    Jousted with the windmill that is JavaScript. Performed unspeakable acts with APIs. Through all the
                    amped up gallantry, I've developed a sense for design and a red-bull-headed refusal to accept that
                    "good enough" is ever good enough. Marco Pierre White once said "Through imperfection, you will
                    discover perfection". This is the work, the "gong", of a problem-solver.</p>

                <p> Navigating the murky waters of IT, Cybersecurity, content management and the never-ending circus
                    parade of acronyms that define the industry, bemused fascination is a description that stands out if
                    we can remember what we were even here for to begin with. A dystopia where the currency is
                    knowledge, and most knowledge is learning how to not let performance become waylaid by algorithms
                    while trying to not misplace sanity. Staying one step ahead of the machines we've created is a
                    derivative juggling ball that is so hard to grip that is slips by the spheres of practicality and
                    spirituality. I approach each challenge with a trimming of sardonic wit, caffeine-fueled
                    determination, and an unexplainable desire to make the internet a slightly less terrible place. In a
                    world gone mad with tech sometimes the only sane response is to embrace the absurdity and code on...
                    and keep calm, just keep calm. </p>
            </div>
  );
}

export default About;