import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import image1 from '../assets/VogueVogue1.jpg';
import image2 from '../assets/RedWitchRedRat1.jpeg';

const HeroContainer = styled.div`
  background: linear-gradient(135deg, rgba(44, 62, 80, 0.9), rgba(231, 76, 60, 0.9));
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 80vh;
  background: transparent; 
`;

const HeroText = styled.h1`
  font-size: 5rem;
  color: #ecf0f1;
  margin-bottom: 2rem;
  line-height: 1.2;
  text-shadow: 4px 4px 10px black;

  span {
    display: inline-block;
    vertical-align: middle;
  }
`;

const ImageSpan = styled.span`
  width: 4rem;
  height: 4rem;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin: 0 1rem;
`;

const CTAButton = styled.button`
  background-color: #e74c3c;
  color: #ecf0f1;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-family: 'Raleway', sans-serif;
  text-shadow: 4px 4px 10px black;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color, font-size 0.4s ease;

  &:hover {
    background-color: #c0392b;
    font-size: 1.5rem;
  }
`;

const HeroSection = () => {
    const navigate = useNavigate();

    const handleExploreClick = () => {
      navigate('/portfolio');
    };
  return (
    <HeroContainer>
      <HeroText>
        Discover
        <ImageSpan style={{backgroundImage: `url(${image1})`}} />
        Journey
        <br />
        Code
        <ImageSpan style={{backgroundImage: `url(${image2})`}} />
        Create
        <br />
        Entertain
      </HeroText>
      <CTAButton onClick={handleExploreClick}>Curriculum Vitae</CTAButton>
    </HeroContainer>
  );
};

export default HeroSection;