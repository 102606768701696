// components/Games.js
import React from 'react';

function Games() {
  return (
    <div id="games" className="content">
      <h1>Game Design</h1>
      <section>
        <iframe
          frameBorder="0"
          src="https://itch.io/embed/1165568?bg_color=0e0505&amp;fg_color=908585&amp;link_color=fa5c5c&amp;border_color=bebebe"
          width="552"
          height="167"
          title="1st Rogue-like by grendeld"
        ></iframe>
      </section>
    </div>
  );
}

export default Games;